<template>
    <div id="pageTable">
        <v-container grid-list-xl fluid>
            <v-row>
                <v-overlay :value="overlay" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p>{{fraseOverlay}}</p>
                    </div>
                </v-overlay>
                <v-col cols="12" xs="12" sm="12" md="12" v-if="!loading" class="px-0">

                    <v-breadcrumbs :items="itemsLinks" v-if="rol != 'empleado'">
                        <template v-slot:divider>
                        <v-icon>chevron_right</v-icon>
                        </template>
                    </v-breadcrumbs>

                    <v-row class="pa-0 mx-n1 mt-0 mb-4">
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
                            <div style="display: flex; width:100%; height:100%; justify-content: flex-start;">
                                <hr class="vr"/>
                                <p class="tblName pb-2">Detalle de empleado</p>
                            </div>
                        </v-col>
                    </v-row>
                
                    <v-col cols="12" class="ma-0 pa-0">
                        <v-card elevation="0" class="borde-card mx-2">
                            <v-list color="#f6f6f6" height="50px">
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title class="encabezado mb-4 centrar">Nombre</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <v-list-item-title class="encabezado pl-10 ml-8 mb-4">Empresa</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <v-list-item-title class="encabezado pl-10 ml-7 mb-4">Antigüedad</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <v-list-item-title class="encabezado pl-10 ml-4 mb-4">Ranking</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <v-list-item-title class="encabezado pl-2 mb-4">Fecha de ingreso</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <v-list height="125px">
                                <v-list-item two-line>
                                    <v-list-item-avatar height="60px" width="60px" class="mt-5">
                                        <img :src="urlAvatar+session_empleado.foto" alt="Empleado">
                                    </v-list-item-avatar>
                                    <v-list-item-content class="ml-02 mt-4">
                                        <v-list-item-title class="list-item-titulo">{{session_empleado.nombre + ' ' + session_empleado.apellido_paterno}}</v-list-item-title>
                                        <v-list-item-subtitle class="list-item-subtitulo">{{session_empleado.correo}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-content class="ml-5 mt-4">
                                        <v-list-item-title class="list-item-titulo">{{ session_empleado.empresa.nombre }}</v-list-item-title>
                                        <v-list-item-subtitle class="list-item-subtitulo">{{ session_empleado.cliente.nombre }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-content class="pl-8">
                                        <v-list-item-title class="list-item-titulo">{{antiguedad}}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <v-rating
                                            v-model="session_empleado.getRanking.length"
                                            size="24"
                                            dense
                                        >
                                            <template v-slot:item="props">
                                                <v-tooltip bottom v-if="props.isFilled">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            :color="props.isFilled ? '#2DB9D1' : '#F2F2F2'"
                                                            class="mx-1"
                                                        >
                                                            {{ props.isFilled ? 'mdi-star' : 'mdi-star' }}
                                                        </v-icon>
                                                    </template>
                                                    <span v-if="props.isFilled">{{session_empleado.getRanking[props.index]}}</span>
                                                </v-tooltip>

                                                <v-icon
                                                    v-else
                                                    :color="props.isFilled ? '#2DB9D1' : '#F2F2F2'"
                                                    class="mx-1"
                                                >
                                                    {{ props.isFilled ? 'mdi-star' : 'mdi-star' }}
                                                </v-icon>
                                            </template>
                                        </v-rating>  
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <v-list-item-title class="list-item-titulo">{{dateFormat(session_empleado.fecha_reingreso?session_empleado.fecha_reingreso:session_empleado.fecha_ingreso)}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                        <v-container fluid class="px-3">
                            <v-row dense>
                                <v-col cols="3" class="my-2 mx-0 py-2 pl-0 pr-2">
                                    <v-card elevation="0" class="borde-card" height="110px">
                                        <v-list>
                                            <v-list-item two-line>
                                                <v-list-item-avatar color="rgba(0, 75, 175, 0.2)" width="60" height="60" style="justify-content: center;">
                                                    <v-icon class="icono-telefono"></v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <p class="titulo-card01">Teléfono</p>
                                                    <v-list-item-title class="subtitulo-card01">{{session_empleado.telefono}}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item> 
                                        </v-list>
                                    </v-card>
                                </v-col>
                                <v-col cols="3" class="my-2 mx-0 py-2 pl-0 pr-2">
                                    <v-card elevation="0" class="borde-card" height="110px">
                                        <v-list>
                                            <v-list-item two-line>
                                                <v-list-item-avatar color="rgba(0, 75, 175, 0.2)" width="60" height="60" style="justify-content: center;">
                                                    <v-icon class="icono-estadoCivil"></v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <p class="titulo-card01">Estado civil</p>
                                                    <v-list-item-title class="subtitulo-card01">{{formatEstadoCivil(session_empleado.estado_civil_id)}}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item> 
                                        </v-list>
                                    </v-card>
                                </v-col>
                                <v-col cols="3" class="my-2 mx-0 py-2 pl-0 pr-2">
                                    <v-card elevation="0" class="borde-card" height="110px">
                                        <v-list>
                                            <v-list-item two-line>
                                                <v-list-item-avatar color="rgba(0, 75, 175, 0.2)" width="60" height="60" style="justify-content: center;">
                                                    <v-icon class="icono-pastel"></v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <p class="titulo-card01">Cumpleaños</p>
                                                    <v-list-item-title class="subtitulo-card01">{{dateFormatCumpleanios(session_empleado.fecha_nacimiento)}}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item> 
                                        </v-list>
                                    </v-card>
                                </v-col>
                                <v-col cols="3" class="my-2 mx-0 py-2 px-0">
                                    <v-card elevation="0" class="borde-card" height="110px">
                                        <v-list>
                                            <v-list-item two-line>
                                                <v-list-item-avatar color="rgba(0, 75, 175, 0.2)" width="60" height="60" style="justify-content: center;">
                                                    <v-icon class="icono-calendario"></v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <p class="titulo-card01">Días de vacaciones</p>
                                                    <v-list-item-title class="subtitulo-card01">{{session_empleado.dias_vacaciones}}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item> 
                                        </v-list>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="12" class="mx-0 px-2">
                        <simple-table
                            :tableName="'Asistencia'"
                            :classes="tableClasses"
                            :rows="itemsAsistencia"
                            :columns="headersAsistencia"
                            :perPage="[5, 25, 50, 100]"
                            shortPagination
                        >
                            <tbody slot="body" slot-scope="{ data }">
                                <tr :key="index" v-for="(item, index) in data">
                                    <td>
                                        <div>{{item.incidencia }}</div>
                                    </td>
                                    <td>
                                        <div>{{ item.fecha }}</div>
                                    </td>
                                    <td>
                                        <div>{{ item.hora_entrada }}</div>
                                    </td>
                                    <td>
                                        <div>{{ item.hora_salida }}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </simple-table>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="12" class="mx-0 mt-4 px-2">
                        <simple-table
                            :tableName="'Historial de vacaciones'"
                            :classes="tableClasses"
                            :rows="itemsVacaciones"
                            :columns="headersVacaciones"
                            :perPage="[5, 25, 50, 100]"
                            shortPagination
                        >
                            <tbody slot="body" slot-scope="{ data }">
                                <tr :key="item.id" v-for="item in data">
                                    <td>
                                        <div>{{ item.concepto }}</div>
                                    </td>
                                    <td>
                                        <div>{{ item.fecha_inicio == 'NA' ? 'NA': dateFormat(item.fecha_inicio)}}</div>
                                    </td>
                                    <td>
                                        <div>{{ item.fecha_fin == 'NA' ? 'NA': dateFormat(item.fecha_fin) }}</div>
                                    </td>
                                    <td>
                                        <div>{{ item.asignadas }}</div>
                                    </td>
                                    <td>
                                        <div>{{ item.disfrutadas }}</div>
                                    </td>
                                    <td>
                                        <div>{{ item.disponibles }}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </simple-table>
                    </v-col> 


                    <v-col cols="12" xs="12" sm="12" md="12" class="mx-0 mt-4 px-2">
                        <simple-table
                            :tableName="'Documentos'"
                            :classes="tableClasses"
                            :rows="itemsDocumentos"
                            :columns="headersDocumentos"
                            :perPage="[5, 25, 50, 100]"
                            shortPagination
                        >
                            <tbody slot="body" slot-scope="{ data }">
                                <tr :key="item.id" v-for="item in data">
                                    <td>
                                        <div>{{ item.nombre }}</div>
                                    </td>
                                    <td>
                                        <div v-if="item.idEntregado">Entregado</div>
                                        <div v-else class="archivo_pendiente-detalle">Pendiente</div>
                                    </td>
                                    <td>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" v-if="item.url" class="botonHover" icon fab small dark @click="descargar(item)">
                                                    <v-icon
                                                        v-text="'$get_app_outline'"
                                                        class="iconoDelgadoBoton"
                                                    ></v-icon>
                                                </v-btn> 
                                            </template>
                                            <span>Descargar</span>
                                        </v-tooltip>
                                    </td>
                                </tr>
                            </tbody>
                        </simple-table>
                    </v-col> 

                    <v-col cols="12" xs="12" sm="12" md="12" class="px-5 mt-7">
                        <data-table
                            ref="tabla"
                            :tableName="'Recibos de nómina'"
                            :columns="headersTimbres"
                            :items="tableData.data"
                            :paginationInfo="tableData.paginatorInfo"
                            @pagination="getPaginationInfo"
                            :perPage="[10,25,50,100]"
                            :loading="isLoading"
                            :showAdvancedFilters="false"
                        >
                            <tbody slot="body" slot-scope="{ data }">
                                <tr :key="item.id" v-for="item in data">
                                    <td>
                                        <div>{{ item.nomina.periodo.numero }} - {{item.nomina.periodo.descripcion_fecha}}</div>
                                    </td>
                                    <td>
                                        <div>{{dateFormat(item.emision)}}</div>
                                    </td>
                                    <td>
                                        <div>{{dateFormat(item.timbrado)}}</div>
                                    </td>
                                    <td  class="d-inline-flex align-center justify-center" style="min-width:369px!important;width:370px !important; max-width:371px!important">
                                        <div class="d-inline-flex align-center justify-center" style="height:60px; min-width:349px!important;width:350px !important; max-width:351px!important">
                                            <v-speed-dial
                                                :id="item.id"
                                                direction="left"
                                                :open-on-hover="true"
                                                transition="scale-transition"
                                                class="elevation-0"
                                            >
                                                <template v-slot:activator>
                                                    <v-btn
                                                        :id="item.id"
                                                        color="#fdfcfc"
                                                        fab
                                                        small
                                                        elevation="0"
                                                    >
                                                        <v-icon
                                                            v-text="'$get_app_outline'"
                                                            class="iconoDelgadoBoton"
                                                        ></v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            v-on="on"
                                                            fab
                                                            small
                                                            color="#FFFFFF"
                                                            @click="descargar(item,false,'pdf')"
                                                            elevation="0"
                                                        >
                                                            <v-icon color="red">mdi-file-pdf</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>PDF</span>
                                                </v-tooltip>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            v-on="on"
                                                            fab
                                                            small
                                                            color="#FFFFFF"
                                                            @click="descargar(item,false,'xml')"
                                                            elevation="0"
                                                        >
                                                            <v-icon color="green">mdi-file-xml</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>XML</span>
                                                </v-tooltip>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            v-on="on"
                                                            fab
                                                            small
                                                            color="#FFFFFF"
                                                            @click="descargar(item,false,'zip')"
                                                            elevation="0"
                                                        >
                                                            <v-icon color="blue">mdi-zip-box</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>ZIP</span>
                                                </v-tooltip>
                                            </v-speed-dial>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </data-table>
                    </v-col> 
                    <v-col cols="12" xs="12" sm="12" md="12" class="mx-0 px-2 mt-4" v-if="rol != 'empleado'">
                        <simple-table
                            :tableName="'Acciones disciplinarias'"
                            :classes="tableClasses"
                            :rows="itemsAcciones"
                            :columns="headersAcciones"
                            :perPage="[5, 25, 50, 100]"
                            shortPagination
                        >
                            <tbody slot="body" slot-scope="{ data }">
                                <tr :key="item.id" v-for="item in data">
                                    <td>
                                        <div>{{ item.tipo }}</div>
                                    </td>
                                    <td>
                                        <div>{{ dateFormat(item.fecha) }}</div>
                                    </td>
                                    <td>
                                        <div class="tblOpciones">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn 
                                                        slot="activator" 
                                                        v-on="on" 
                                                        v-if="item.url_archivo" 
                                                        class="botonHover" 
                                                        icon 
                                                        fab 
                                                        small 
                                                        dark 
                                                        @click="descargarArchivoAccion(item)"
                                                    >
                                                        <v-icon class="tamIconoBoton invertirColorBotones">get_app</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Descargar</span>
                                            </v-tooltip>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </simple-table>
                    </v-col>

                </v-col>
            </v-row>
        </v-container>
    </div>
</template>


<script>
import Vue from "vue";
import {mapState, mapActions} from "vuex";
import Notify from '@/plugins/notify';
import Swal from "sweetalert2";
import Star from '@/components/rating/Star.vue';
import urlImg from '@/plugins/Urlimagen';
import empleadosApi from '@/api/empleados';
import registrosApi from '@/api/registros';
import accionesDisciplinariasApi from '@/api/accionesDisciplinarias'
import { constants } from 'fs';
import tipoApi from '@/api/tipo_documentos';
import documentoApi from '@/api/documentos';
import Datatable from "@/components/simpletable/Datatable.vue";
import Datatable2 from '@/components/g-datatable/Datatable.vue';
import queries from '@/queries/DetalleEmpleado';
import queries2 from '@/queries/Timbres';
import moment from 'moment';

export default {
    props: {
        paramIdEmpleado: {
            type: String,
            default: ''
        }
    },
    components:{
        'simple-table': Datatable,
        'data-table': Datatable2,
        'rating-star' :Star,
    },
    data() {
        return {
            overlay:true,
            fraseOverlay:'Cargando',
            loading: true,
            complex:{
                selected:[],
                headers:[
                    { text:"Empleado", value:"nombre" },
                    { text:"Empresa", value:"empresa" },
                    { text:"Antiguedad", value:"antiguedad"},
                    { text:"Ranking", value: "ranking"},
                    { text:"Fecha de ingreso", value: "fecha_ingreso"}
                ],
                items:[],
            },
            historial:{
                selected:[],
                headers:[
                    { text: "Incidencia", value: "incidencia", class:'cabecera-tabla-izq'},
                    { text: "Fecha", value: "fecha", class:'cabecera-tabla' },
                    { text: "Hora Entrada", value: "hora_entrada", class:'cabecera-tabla' },
                    { text: "Salida", value: "hora_salida", class:'cabecera-tabla-der' },
                ],
                items:[]
            },
            pagination:{
                sortBy:'fecha'
            },
            documentos:{
                selected:[],
                headers:[
                    { text:"Nombre", value:"nombre", class:'cabecera-tabla-izq' },
                    { text:"Estatus", value:"estatus", class:'cabecera-tabla' },
                    { text:"Opciones", value:"opciones", class:'cabecera-tabla-der' }
                ],
                items:[],
            },
            tableClasses: {
                    "t-head" : {
                    "tamanio-head":true
                }
            },
            headersAsistencia: [
                { label: 'Incidencia'   , name: 'incidencia'  , filterable: false},
                { label: 'Fecha'        , name: 'fecha'       , filterable: false},
                { label: 'Hora Entrada' , name: 'hora_entrada', filterable: false},
                { label: 'Salida'       , name: 'hora_salida' , filterable: false},
            ],
            headersDocumentos: [
                { label: 'Nombre'       , name: 'nombre'      , filterable: false},
                { label: 'Estatus'      , name: 'estatus'     , filterable: false},
                { label: 'Opciones'     , name: 'opciones'    , filterable: false},
            ],
            headersTimbres: [
                { label: 'Periodo de pago'       , name: 'periodo'      , filterable: false},
                { label: 'Emitido'      , name: 'emision'     , filterable: false},
                { label: 'Timbrado'      , name: 'timbrado'     , filterable: false},
                { label: 'Opciones'     , name: 'opciones'    , filterable: false , align: "center"},
            ],
            headersAcciones: [
                { label: 'Tipo'       , name: 'tipo'      , filterable: false},
                { label: 'Fecha'      , name: 'fecha'     , filterable: false},
                { label: 'Opciones'     , name: 'opciones'    , filterable: false, align: 'center'},
            ],
            headersVacaciones:[
                { label: 'Concepto'     , name: 'concepto',     filterable: false},
                { label: 'Fecha inicio' , name: 'fecha_inicio', filterable: false},
                { label: 'Fecha fin'    , name: 'fecha_fin',    filterable: false},
                { label: 'Asignadas'    , name: 'asignadas',    filterable: false},
                { label: 'Disfrutadas'  , name: 'disfrutadas',  filterable: false},
                { label: 'Disponibles'  , name: 'disponibles',  filterable: false},
            ],
            itemsAsistencia: [],
            itemsDocumentos: [],
            itemsTimbres: [],
            itemsAcciones: [],
            itemsVacaciones: [],

            filters : {AND:[]},
            cliente_id: null,
            empresa_id: null,
            sucursal_id: null,
            departamento_id: null,
            session_empleado: null,
            datosLogin: null,
            rol: null,
            urlAvatar:null,
            months      : ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
            cumpleanios:null,
            estadoCivil:null,
            diasVacaaciones:null,
            entregados:[],
            tipos: [],
            conteo_parametros: 0,
            itemsLinks: [],
            antiguedad: null,
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
        }
    },
    methods:{
        ...mapActions('tabNavigation',['updateTabName']),
        
        dateFormat(date) {
            let fecha = date.split(" ");
            let dateFirst = fecha[0].split("-");
            return dateFirst[2] + '/' + dateFirst[1] + '/'+ dateFirst[0]; 
            
        },
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }

            //this.$apollo.queries.anios_fiscales;
        },
        dateFormatCumpleanios(date){
            let fecha = new Date();
            let dateFirst = date.split("-");
            return dateFirst[2] + '/' + dateFirst[1] + '/'+ fecha.getFullYear();
        },
        formatEstadoCivil(id){
            switch(id){
                case 1:
                    return 'Soltero'
                    break;
                case 2:
                    return 'Casado'
                    break;
                case undefined:
                    return ''
                    break; 
                default:
                    return ''
                    break;
            }
        },
        listar(){
            this.datosLogin = this.$session.get('usuario');
            this.rol = this.datosLogin.rol.name;
            this.breadcrumbs();

            let fecha = new Date();
            let mes = String(fecha.getMonth() + 1);
            let dia = String(fecha.getDate());

            if(mes.length < 2){
                mes = "0" + mes;
            }
            if(dia.length < 2){
                dia = "0" + dia;
            }
            this.inicio = fecha.getFullYear() + "-" + mes + "-01";
            this.final = fecha.getFullYear() + "-" + mes + "-" + dia + " 23:59:00";

            if(this.rol == "admin"){
                this.cliente_id = this.datosLogin.cliente_id;
            }
            else if(this.rol == "admin-empresa"){
                this.empresa_id = this.datosLogin.empresa_id;
            }
            else if(this.rol == "admin-sucursal"){
                this.sucursal_id = this.datosLogin.sucursal_id;
            }
            else if(this.rol == "admin-depto"){
                this.departamento_id =  this.datosLogin.departamento_id
            };

            if(this.fecha_inicio != null){
                this.inicio = this.fecha_inicio;
            }
            if(this.fecha_final != null){
                this.final = this.fecha_final;
            }

            this.antiguedad = this.session_empleado.getAntiguedad;

            let cumple = this.session_empleado.fecha_nacimiento.split('-');
            this.cumpleanios = cumple[2]+'-'+cumple[1]+'-'+fecha.getFullYear();

            let param = {
                rol_name            : this.datosLogin.rol.name, 
                usuario_id          : this.datosLogin.id, 
                paginate            : false, 
                cliente_id          : this.cliente_id, 
                empresa_id          : this.empresa_id, 
                sucursal_id         : this.sucursal_id, 
                departamento_id     : this.departamento_id, 
                empleado_id         : this.session_empleado.id,
                min_fecha_checada   : this.inicio, 
                max_fecha_checada   : this.final, 
                include_horario     : true 
            };
            this.parametros_actuales = param;
            registrosApi.getAsistencias(param).then((response)=>{
                let formateado = response;
                this.historial.items = [];
                this.itemsAsistencia = [];
                formateado.forEach(element => {
                    let dia = element['dia'];
                    let horario = element['horario'];

                    let fecha_ok = element["fecha"].split("-");
                    element['fecha'] = fecha_ok[2] + '/' +  fecha_ok[1] + '/' + fecha_ok[0];  
                    this.itemsAsistencia.push(element);
                });  
            })
            .catch(err => {
                this.respuesta_error(err);
                console.log(err);
            });

            let param_tipo = { activo:true, paginate: false, empresa_id:this.session_empleado.empresa_id };
            let param_doc = { empleado_id: this.session_empleado.id,include_tipo:true,activo:true,paginate: false };
            let param_vacaciones = { 
                activo:true, 
                paginate: false, 
                empresa_id:this.session_empleado.empresa_id, 
                empleado_id: this.session_empleado.id, 
                tipo_vacaciones:"reales", 
                tipo_reporte:"detallado",
                tipo_archivo:"json"
            };

            documentoApi.getDocumentos(param_doc).then((response)=>{
                this.entregados= response.data;
                this.finaliza_parametros();
            })
            .catch(err => {
                this.respuesta_error(err);
                console.log(err);
            });

            tipoApi.getDocumentos(param_tipo).then((response)=>{
                this.tipos= response.data;
                this.finaliza_parametros();
            })
            .catch(err => {
                this.respuesta_error(err);
                console.log(err);
            })

            empleadosApi.historicoVacaciones(param_vacaciones).then((response)=>{
                this.itemsVacaciones = response.data;
            })
            .catch(err => {
                this.respuesta_error(err);
                console.log(err);
            })

            if(this.rol != 'empleado'){
                let parametros = {
                    activo: true,
                    paginate: true,
                    cliente_id: null,
                    empresa_id: null,
                    empleado_id: this.session_empleado.id,
                    order_by: "tipo",
                    order: "asc",
                }

                accionesDisciplinariasApi.getAccionesDisciplinarias(parametros).then((response) =>{
                    this.itemsAcciones = response.data
                }).catch((error) =>{
                    this.respuesta_error(error)
                    console.log(error);
                })
            }
        },
        getColor(incidencia){
            switch(incidencia){
                case "Salida anticipada":
                    return 'rgba(250,84,113,0.1)'
                    break;
                case "Falta sin goce":
                    return 'rgba(250,84,113,0.1)'
                    break;
                case "Retardo":
                    return 'rgba(250,84,113,0.1)'
                    break;
                case "No hay registro":
                    return 'rgba(95,92,97,0.1)'
                    break;
                case undefined:
                    return ''
                    break; 
                default:
                    return 'rgba(32,224,110,0.1)'
                    break;
            }
        },
        getColorTexto(incidencia){
            switch(incidencia){
                case "Salida anticipada":
                    return '#FA5471'
                    break;
                case "Falta sin goce":
                    return '#FA5471'
                    break;
                case "Retardo":
                    return '#FA5471'
                    break;
                case "No hay registro":
                    return '#5F5C61'
                    break;
                case undefined:
                    return ''
                    break;  
                default:
                    return '#20E06E'
                    break;
            }  
        },
        finaliza_parametros(){
            this.conteo_parametros = this.conteo_parametros + 1; 
            if(this.conteo_parametros == 2){
                this.loading = false;
                this.overlay  = false;
                for(var i = 0; i < this.tipos.length; i++){        
                    for(var j = 0; j < this.entregados.length; j++){
                        if( this.entregados[j].tipo_id == this.tipos[i].id){
                            this.tipos[i].idEntregado = this.entregados[j].id;
                            this.tipos[i].url = this.entregados[j].url;
                        }
                    }
                }
                this.itemsDocumentos = this.tipos;
            }
        },
        async descargar(item = null,documento = true, tipo){
            this.overlay = true;
            this.fraseOverlay = "Descargando";
            let self = this;
            if(documento){
                const FileDownload = require('js-file-download');
                const axios = require('axios');
                let url = 'incidencias/download';
                var nombre_archivo = item.url.split("/"); 
                nombre_archivo = item.nombre + "_" + nombre_archivo[3];
                let parametros = { url_archivo: item.url }
                try {
                    axios({
                    method: 'post',
                    url: url,
                    responseType: 'blob',
                    data: parametros,
                    })
                    .then(function (response) {
                        self.overlay = false;
                        FileDownload(response.data,nombre_archivo);
                    });
                } catch (error) {
                    self.overlay = false;
                    console.log(error);
                }
            }else{
                const FileDownload = require('js-file-download');
                const axios = require('axios');
                let url = 'nominas-timbres/descargar';
                let fecha = item.timbrado.split(' ');
                var nombre_archivo = "Recibo_de_nomina_"+fecha[0]+"."+tipo; 
                let parametros = {nomina_id:[item.id],tipo}
                try {
                    axios({
                    method: 'post',
                    url: url,
                    responseType: 'blob',
                    data: parametros,
                    })
                    .then(function (response) {
                        self.overlay = false;
                        FileDownload(response.data,nombre_archivo);
                    
                    });
                } catch (error) {
                    self.overlay = false
                    console.log(error);
                }
            }
            
        },
        respuesta_error(err){
            if(typeof err.response.data.error === 'string'){
                Notify.ErrorToast(err.response.data.error);
            }else{
                let error = Object.values(err.response.data.error);
                let leyenda = ``;

                for (var i = 0; i < error.length; i++) {
                    leyenda+= `* ` + error[i] + `\n`;
                }

                Notify.ErrorToast(leyenda);
            }
        },
        breadcrumbs(){
            if( this.rol != 'empleado' ){
                if( this.session_empleado.activo == false && this.session_empleado.baja == true){
                    this.itemsLinks = [
                        {
                            text: 'Empleados Inactivos',
                            disabled: false,
                            to: '/empleado/empleadosInactivos',
                        },
                        {
                            text: 'Detalle de empleado',
                            disabled: true,
                            to: '/detalleEmpleado',
                        },
                    ];
                }
                else{
                    this.itemsLinks = [
                        {
                            text: 'Empleados',
                            disabled: false,
                            to: '/empleado/empleados',
                        },
                        {
                            text: 'Detalle de empleado',
                            disabled: true,
                            to: '/detalleEmpleado',
                        },
                    ];
                }
            } 
        },

        async descargarArchivoAccion(item) {
            let empleado = item.nombre_completo;
            let archivo = item.url_archivo;
            const FileDownload = require("js-file-download");
            const axios = require("axios");
            let url = "download";
            let parametros = { url: archivo };

            var nombre_archivo = "";
            let nombreArchivo = "";
            let archivo_extension = "";

            if (archivo) {
                nombreArchivo = archivo.split("/");
                archivo_extension = nombreArchivo[nombreArchivo.length - 1].split(".");
                nombre_archivo =
                    empleado + "." + archivo_extension[archivo_extension.length - 1];
            }

            try {
                axios({
                    method: "post",
                    url: url,
                    responseType: "blob",
                    data: parametros,
                }).then((response) => {
                    FileDownload(response.data, nombre_archivo);
                });
            } catch (e) {
                Notify.ErrorToast("Se produjo un error al realizar la petición.");
                console.log(e);
            }
        }

    },
    async created() {
        this.datosLogin = this.$session.get('usuario');
        this.rol = this.datosLogin.rol.name;
        if(this.rol === "empleado"){
            let param = {
                rol_name: this.rol,
                usuario_id: this.datosLogin.id,
                empleado_id: this.datosLogin.empleado_id,
                //correo:this.datosLogin.email,
                include_padres: true
            }
            
            await empleadosApi.getEmpleados(param).then((response) => {
                this.$session.set("detalleEmpleado",response.data[0]);
            })
            .catch((error) => {
                console.log(error);
            }); 
        }
       
        this.$apollo.queries.empleado.skip = false;

        this.filters = {AND:[
            { column: 'EMPLEADO_ID', value: this.$session.get('detalleEmpleado').id },
            { column:"ERRORES", operator:"IS_NULL"},
            { column:"CANCELADO", value:false}
        ]};
        this.$apollo.queries.timbres.skip = false;

        this.urlAvatar          = urlImg.getURL();
        this.complex.items.push(this.session_empleado);
        if(this.rol != "empleado"){
            this.updateTabName("Empleados");
        }
        
        //this.listar();
    },
    apollo: {
        empleado: {
            query       : queries.getEmpleadoQuery,
            fetchPolicy : 'network-only',
            variables() {
                if(this.$session.get('detalleEmpleado') == null || this.$session.get('detalleEmpleado') == undefined){
                    this.$router.push('/');
                }
                return {
                    id : parseInt(this.$session.get('detalleEmpleado').id)
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.session_empleado = this.empleado;
                this.listar();
            }
        },
        timbres: {
            query       : queries2.timbresTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading = true;
                return {
                    whereConditions : this.filters,
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : 'id',
                    Order       : 'DESC',
                }
            },
            skip(){
                return true;
            },
            result({loading, error}) {
                this.isLoading    = false;
                this.tableData  = this.timbres;
               
            },
        },
    },
}
</script>

<style scoped>
    .tituloPrincipalToolbar{
        border-left-style: solid; 
        border-left-color: #F0C087 !important;
    }
    .tipoLetra{
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.34px;
        line-height: 17px;
    }
    .tituloPrincipal{
        color: #05283B;
        font-family: 'Montserrat';
        font-size: 22px;
        font-weight: 700;
        letter-spacing: -0.1px;
        line-height: 26px;
        text-align: left;
    }
    .transparente{
        background-color: transparent;
        padding: 0 0;
    }
    .tipoLetra700GrisDetalles, .tipoLetra700FechaHora{
        color: rgba(121, 141, 152, 0.6);
        font-family: 'Montserrat';
        font-size: 18px;
        font-weight: 700;
        letter-spacing: -0.43px;
        line-height: 22px;
    } 
    .subtitulos{
        color:#05283B;
        font-family: 'Montserrat';
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.39px;
    }
    .error_checada{
        color:red !important;
    }
    .verde{
        color: #0EB60E !important;
    }
    .vChip{
        background-color: transparent;
        width:200px;
        justify-content: center;
    }
    .vChip .v-chip .v-chip__content{
         height: 20px;
    }
    .trIncidencias {
        width:200px;
    }
    .tamIcono{
        height: 24px !important;
        align-self: center !important;
    }
    .invertirColor{
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(0,0,0,0.54);
    }
    .iconoDelgado{
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-fill-color: rgba(0,0,0,0.54);
    }
    .color_rojo{
        color:red;
    }
    .tablaDocumentos table.v-table thead tr {
        height: 20px;
    }
    .transparente{
        background-color: transparent;
        padding: 0 0;
    }
    .archivo_entregado{
        color:#002438;
        font-family: 'Montserrat';
        font-size: 12px;
        font-weight: 700;
        letter-spacing: -0.29px;
        line-height: 14px;
    }
    .archivo_pendiente-detalle{
        color:red;
    }  
    .v-list__tile__sub-title {
        color: #ACB6BB !important;
        font-family: 'Montserrat' !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        letter-spacing: -0.34px !important;
        line-height: 17px !important;
    } 
    .tituloSeccion{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 39px;
        color: #101C5A;
    }
    .titulo-card01{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        color: #96999A;
    }
    .subtitulo-card01{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 22px;
        color: #96999A;
    }
    .encabezado{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #828282;
    }
    .list-item-titulo{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #1E2245;
    }
    .list-item-subtitulo{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #96999A;
    }
    .texto-tabla{
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 18px !important;
        line-height: 22px !important;
        color: #96999A !important;
    }
    .borde-tabla.v-data-table {
        border-radius: 20px !important;
    }
</style>
